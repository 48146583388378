
import React from 'react'
import './style.scss'

const HighlightLink = ({ text, href }) => {
  return (<span><a className='highlight-link' href={href} target='_blank' rel='noreferrer'><b>{`${text}`}</b></a>{''}</span>)
}
export default () => (<div className='timeline-component'>
  <div className='title-component'>timeline</div>
  <p className='side'>Founding engineer at <HighlightLink text='Patlytics' href='https://patlytics.ai' /><span className='date'>2024/08/01</span></p>
  <p className='side'>Re-launched <HighlightLink text='@TeamCloudNative' href='https://teamcloudnative.com' /><span className='date'>2024/07/01</span></p>
  <p className='general'>Started to <HighlightLink text='learn Japanese on Duolingo' href='https://www.duolingo.com/profile/amazingandyyy' /><span className='date'>2024/05/10</span></p>
  <p className='side'>Launched <HighlightLink text='Ukulake' href='https://ukulake.amazyyy.com/' /><span className='date'>2024/01/15</span></p>
  <p className='side'>Built <HighlightLink text='IntelligentHost' href='https://host.amazingandyyy.com' /><span className='date'>2024/01/12</span></p>
  <p className='general'>We adopted <HighlightLink text='Rem & Baby Yam 🐈' /><span className='date'>2023/11/24</span></p>
  <p className='side'>Launched <HighlightLink text='Dublin Thread 🍀' href='https://dublin.amazyyy.com' /><span className='date'>2023/06</span></p>
  <p className='general spotlight'>We bought our first <HighlightLink text='house 🏠' /><span className='date'>2023/06/30</span></p>
  <p className='general'>I start <HighlightLink text='playing ukulele' /><span className='date'>2023/04</span></p>
  <p className='general'>Became <HighlightLink text='A Host' href='https://turo.com/us/en/drivers/1136789'/>on <HighlightLink text='Turo' href='https://turo.com/us/en/suv-rental/united-states/dublin-ca/honda/cr-v/1589934'/><span className='date'>2022/07</span></p>
  <p className='general'>Became <HighlightLink text='Top Mentor' href='https://mentors.to/amazingandyyy'/>on Mentorcruise<span className='date'>2022/07</span></p>
  <p className='general spotlight'>Became an <b>USA immigrant</b> <span className='date'>2022/06</span></p>
  <p className='move'>London ✈ Dublin CA in 2022</p>
  <p className='side'>Hassled on <HighlightLink text='ActionSpaces' href='https://actionspaces.vercel.app/?mode=preview' /><span className='date'>2022/03</span></p>
  <p className='side'>Started mentoring on <HighlightLink text='MentorCruise' href='https://mentors.to/amazingandyyy' /><span className='date'>2021/12</span></p>
  <p className='side'>Hassled on <HighlightLink text='TeamCloudNative' href='https://teamcloudnative.com' /><span className='date'>2021/11</span></p>
  <p className='move'>San Francisco ✈ London in 2021</p>
  <p className='side'>Hassled on our first <HighlightLink text='NFT project' href='' />with my wife<span className='date'>2021/04</span></p>
  <p className='general spotlight'><HighlightLink text='Got Married' href='https://www.youtube.com/watch?v=-jDu5FKeUKI&ab_channel=ShellyCheng' /> with my amazing wife<span className='date'>2020/06/22</span></p>
  <iframe width='100%' height='400px' src='https://www.youtube.com/embed/-jDu5FKeUKI' frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
  <p className='general'>DevOps at <HighlightLink text='Turo' href='https://www.turo.com' /><span className='date'>2020/06</span></p>
  <p className='side'>Hassled on <HighlightLink text='MapItOut' href='http://mapitout.github.io/' /> with my wife <span className='date'>2020/04</span></p>
  <p className='general'>Graduated from <HighlightLink text='YC Startup School Online' href='https://www.startupschool.org' /><span className='date'>2019/10</span></p>
  <p className='side'>Hassled on <HighlightLink text='DecoHash' href='https://decohash.github.io' /><span className='date'>2019/08</span></p>
  <p className='general'>Infrastructure Engineering at <HighlightLink text='Minted' href='https://www.minted.com' /><span className='date'>2019/03</span></p>
  <p className='move'>Daly City ✈ San Francisco in 2019</p>
  <p className='general'>Graduated from <HighlightLink text='Draper University' href='https://www.draperuniversity.com' /> as Top3<span className='date'>2018/11</span></p>
  <p className='general'>Fouding-CTO of <HighlightLink href='https://bsos.tech' text='BSOS' /> <span className='date'>2018/08</span></p>
  <p className='general'>Founded <HighlightLink href='https://onbns.github.io/www' text='Blockchain Node Service (BNS)' /> <span className='date'>2018/07</span></p>
  <p className='move'>Berkeley ✈ Daly City in 2018</p>
  <p className='side'>Hassled on <HighlightLink href='https://github.com/amazingandyyy/leptin' text='leptin' /><span className='date'>2018/03</span></p>
  <p className='side'>Hassled on <HighlightLink href='https://revieweer.github.io' text='revieweer' /><span className='date'>2018/02</span></p>
  <p className='side'>Hassled on <b>Shepherd</b> side project <span className='date'>2017/10</span></p>
  <p className='general'>CPO at <b>Ventured Network</b> <span className='date'>2017/08</span></p>
  <p className='general'>Bought my first <b>Ethereum($42)</b> and <b>Bitcoin($1240)</b> <span className='date'>2017/04</span></p>
  <p className='general'>Studied <b>Cognitive Science</b> at <b>UC Berkeley</b> <span className='date'>2017/08</span></p>
  <p className='move'>Fremont ✈ Berkeley in 2016</p>
  <p className='side'>Built <b>Seperpay</b> side project <span className='date'>2016/07</span></p>
  <p className='side'>Built <b>JobRM</b> side project <span className='date'>2015/06</span></p>
  <p className='side'>Built <b>VoiceAlbum</b> side project <span className='date'>2015/05</span></p>
  <p className='general'>Studied fullstack JS at <HighlightLink href='https://amazingandyyy.com/codinghouse/project/0412ArithmeticGame/index.html' text='CodingHouse' /> <span className='date'>2016/04</span></p>
  <p className='move'>Mountain View ✈ Fremont in 2016</p>
  <p className='general'>Served as CTO of <b>YeahEdu</b> <span className='date'>2015/12</span></p>
  <p className='general'>Changed Major to <b>Anthropology</b> <span className='date'>2015/08</span></p>
  <p className='general'>Did <b>Archaeology</b> in Ireland <span className='date'>2015/07</span></p>
  <p className='side'>Built <b>Groove Trend</b> side project <span className='date'>2015/04</span></p>
  <p className='general'>Founded <b>Foothill Taiwanese Association</b> <span className='date'>2014/11</span></p>
  <p className='general'>Studied <b>Graphic&iX design</b> at <b>Foothill College</b> <span className='date'>2014/08</span></p>
  <p className='general'>Move to the <b>United States</b> <span className='date'>2014/08</span></p>
  <p className='move'>Shanghai ✈ Mountain View in 2014</p>
  <p className='general'>Founded <b>Willo</b> <span className='date'>2013/07</span></p>
  <p className='general'>Served as president of <b>An-Tai Economics Debate Team</b> <span className='date'>2013/10</span></p>
  <p className='general'>Studied economics at <b>SJTU</b> <span className='date'>2012/08</span></p>
  <p className='move'>Taipei ✈ Shanghai in 2012</p>
  <p className='general'><b>Graduated</b> from <b>HSNU</b> <span className='date'>2012/08</span></p>
  <p className='general'>Won <b>Golden Medal</b> of <b>Geography Olympiad</b> <span className='date'>2011/12</span></p>
  <p className='move'>New Taipei ✈ Taipei in 2006</p>
  <p className='general'>Authored <b>ㄅㄨ！ㄅㄨ！車 comic series</b> <span className='date2004/' /></p>
  <p className='move'>Yun Lin ✈ New Taipei in 1996</p>
  <p className='general spotlight'> <b>Born in Yun Lin on Valentines Day</b><span className='date'>1994/02/14</span></p>
  <p className='move'>Heaven ✈ World in 1994</p>
</div>)
