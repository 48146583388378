import React from 'react'
// eslint-disable-next-line no-unused-vars
import Module from './module'

export default () => (<div className='fav-component'>
  <div className='title-component' id='favorite'>
    My Favorites
  </div>
  <Module />
</div>)
