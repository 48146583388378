import React from 'react'
import './style.scss'

const Link = ({href, children}) => (<a className='gradient-txt' target='_blank' rel='noreferrer' href={href}>{children}</a>)

export default () => (<div className='recent-component'>
  <div className='list'>
    <div>I play <Link href='https://duolingo.com/'>Duolingo</Link></div>
    <div>Love watching movies in 4DX and camping on weekends</div>
    <div>Currently enjoy living <Link href='https://www.dublin.ca.gov/'>Dublin CA</Link> too much</div>
    <div>Plan to deep dive into computer networking and ADHD</div>
  </div>
</div>)
