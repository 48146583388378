import React from 'react'
import { createRoot } from "react-dom/client";
// eslint-disable-next-line no-unused-vars
import Home from './components/home'

import './styles/style.scss'


const container = document.getElementById("app");
const root = createRoot(container)
root.render(<Home />);
