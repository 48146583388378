import React from 'react'
import './style.scss'
const d = new Date()

function timeSince (date) {
  const seconds = Math.floor(((new Date().getTime()) - date)) / 1000
  let interval = Math.floor(seconds / 31536000)

  if (interval > 1) return interval + ' year'

  interval = Math.floor(seconds / 2592000)
  if (interval > 1) return interval + ' months'

  interval = Math.floor(seconds / 86400)
  if (interval >= 1) return interval + ' days'

  interval = Math.floor(seconds / 3600)
  if (interval >= 1) return interval + ' hours'

  interval = Math.floor(seconds / 60)
  if (interval > 1) return interval + ' minutes'

  return Math.floor(seconds) + ' seconds'
}

export default () => {
  return (
    <div className='component footer'>
      <div>Built with ❤️</div>
      <div>{`updated ${timeSince(process.env.LAST_UPDATE)}`} ago in Dublin, CA</div>
      <div className='signature'>amazingandyyy © {d.getFullYear()}</div>
    </div>
  )
}
