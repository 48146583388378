import React from 'react'
import './style.scss'

import Timeline from './timeline'
import Contacts from './contacts'
import Recent from './recent'
import Works from './works'
import Subscriptions from './subscriptions'
import Footer from './footer'
import Branding from './branding'

export default () => (
  <React.Fragment>
    <div className='landing'>
      <div>
        <Branding />
        <Contacts />
        <Recent />
        <Works />
      </div>
    </div>
    <div className='component home'>
      <Timeline />
      {/* <Favorites /> */}
      {/*<Subscriptions />*/}
      <Footer />
    </div>
  </React.Fragment>
)
