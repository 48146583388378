export default {
  apps: {
    meta: {
      height: '120px',
      width: '120px'
    },
    list: [{
      name: 'Headway',
      link: 'https://apps.apple.com/us/app/headway-daily-book-summaries/id1457185832',
      img: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/30/db/f3/30dbf317-0091-25fc-8b91-931261acf9a1/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220-0.png/230x0w.webp'
    }, {
      name: 'Artifact',
      link: 'https://apps.apple.com/us/app/artifact-feed-your-curiosity/id1572927568',
      img: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/02/bd/57/02bd5701-8e87-a940-6577-b6d28d3425f5/AppIcon-0-1x_U007emarketing-0-7-0-sRGB-85-220.png/230x0w.webp'
    }, {
      name: 'Balance',
      link: 'https://apps.apple.com/to/app/balance-meditation-sleep/id1361356590',
      img: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/0d/4a/b7/0d4ab7b6-bdc0-6a6b-4900-26f029c03f2c/AppIcon-0-1x_U007emarketing-0-7-0-85-220.png/230x0w.webp'
    }]
  },
  learning: {
    meta: {
      height: '120px',
      width: '120px'
    },
    list: [{
      name: 'KodeKloud',
      link: 'https://kodekloud.com/',
      img: 'https://res.cloudinary.com/practicaldev/image/fetch/s--jyM73kU4--/c_fill,f_auto,fl_progressive,h_320,q_auto,w_320/https://dev-to-uploads.s3.amazonaws.com/uploads/user/profile_image/287899/000b41db-819a-4b9d-81d5-bce3c94cae34.png'
    }, {
      name: 'ZeroToMatery',
      link: 'https://apps.apple.com/us/app/artifact-feed-your-curiosity/id1572927568',
      img: 'https://pbs.twimg.com/profile_images/1235348786387574784/EHFYcgX6_400x400.png'
    }, {
      name: 'MasterClass',
      link: 'https://apps.apple.com/us/app/masterclass-become-more-you/id1273867416',
      img: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/96/07/e2/9607e2d1-9747-8212-a979-c9712a37bae8/AppIcon-0-0-1x_U007emarketing-0-7-0-sRGB-85-220.png/230x0w.webp'
    }]
  }
}
